import i18next from 'i18next';
const t = i18next.t

import inlineSVG from "../../common/js/inlineSVG"

export default {
  inlineSVG,
  i18next,
  t
}
